@import '../../utils/stylesVariables';

.broken-round-info {
    display: flex;
    flex-direction: column;
    &__item {
        display: flex;
        justify-content: space-between;
    }
    &__value {
        width: 60%;
        text-align: right;
    }
}

.broken-round-info__modal {
    .ant-modal-body {
        height: 60vh;
        overflow: auto;
    }
}

.broken-rounds-control-button {
    padding-left: 7px;
    padding-right: 7px;
}

.column-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 20px;
}

.table-pagination {
    .ant-pagination-item {
        min-width: 24px;
    }
}

.broken-rounds-search {
    &__wrapper.ant-input-group {
        display: flex;
        margin-bottom: 8px;
        margin-top: 8px;
        .broken-rounds-search__select {
            .ant-select-selector {
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
            }
        }
        .ant-btn-primary {
            background: #cf0920;
            border-color: #cf0920;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }
        .broken-rounds-search__status {
            width: 250px;
        }
        .broken-rounds-search__datepicker {
            width: 420px;
        }
    }
    &__clear {
        opacity: 0;
        transition: 0.3s;
        cursor: text !important;
        &__visible {
            opacity: 1;
            cursor: pointer;
        }
    }
    &__refresh {
        width: 60px;
        border-radius: 5px;
        margin-right: 16px;
    }
    &__wrapper {
        margin-bottom: 8px;
        display: flex;
        width: 65%;
    }
    &__pagination {
        margin-bottom: 8px;
        display: flex;
        width: 40%;
        .ant-pagination {
            margin-right: 0;
            margin-left: auto;
        }
    }
}

.statusHistory {
    &__item {
        display: flex;
        justify-content: space-between;
    }
}

.app-table {
    &__header {
        position: sticky;
        top: 0;
    }
}

.wallet-error-cell {
    max-width: 400px;
    display: block;
    word-break: break-all;
}
